<template>
  <div id="currency-convert">
    <CurrencyConvertUpdatedAt :timestamp="timestamp" />
    <CurrencyConvertRow
      v-for="(currency, index) in selectedCurrencies"
      :key="`${currency.code}-${index}`"
      :currency="currency"
      :rate="getRate(activeRowCurrency, currency.code)"
      :active-value="activeValue"
      :active="activeRow === index"
      :editting="editting"
      @click="setActive(index)"
      @input="handleInput(index, $event)"
      @currency-changed="handleCurrencyChanged(index, $event)"
      @remove-currency="removeSelectedCurrency(index)"
    />
    <div id="actions">
      <BaseCurrencySelect
        :value="currencyCodeToAdd"
        @currency-changed="addingCurrencyCodeChange"
      />
      <button
        class="button is-primary has-text-white"
        :disabled="disableAddButton"
        @click="addCurrency"
      >
        {{ $t('convert.add') }}
      </button>
      <button
        class="button is-danger has-text-white"
        @click="toggleEdit"
      >
        {{ editting ? $t('convert.finish_delete') : $t('convert.delete') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import currencies from '../data/currencies.json';
import { ref, computed, provide, watch } from 'vue';
import CurrencyConvertRow from './CurrencyConvertRow.vue';
import BaseCurrencySelect from './BaseCurrencySelect.vue';
import CurrencyConvertUpdatedAt from './CurrencyConvertUpdatedAt.vue';

const props = defineProps({
  timestamp: {
    type: Number,
    default: () => Math.floor(Date.now() / 1000)
  },
  rates: {
    type: Object,
    default: () => ({})
  }
});

// available currencies
const availableCurrencies = computed(() => {
  const currencyInApi = Object.keys(props.rates);
  return Object.values(currencies).filter((c) => !c.crypto || currencyInApi.includes(c.code)).sort((a, b) => {
    return a.code.localeCompare(b.code)
  });
});
provide('availableCurrencies', availableCurrencies.value);

// selected currencies get & set
const getStoredCurrencyCodes = () => {
  const codes = localStorage.getItem("convertCurrencyCodes") || 'USD,GBP,EUR,JPY,CNY';
  return codes.split(',');
};
const setStoredCurrencyCodes = (codes) => {
  localStorage.setItem("convertCurrencyCodes", codes);
};
const selectedCurrencyCodes = ref(getStoredCurrencyCodes());
const selectedCurrencies = computed(() => selectedCurrencyCodes.value.map(c => currencies[c]));
const handleCurrencyChanged = (index, currencyCode) => {
  selectedCurrencyCodes.value[index] = currencyCode;
};
watch(
  () => selectedCurrencyCodes.value,
  (newValue) => {
    setStoredCurrencyCodes(newValue);
  },
  { deep: true }
);

// adding new currency
const currencyCodeToAdd = ref('USD');
const addingCurrencyCodeChange = (code) => {
  currencyCodeToAdd.value = code;
};
const addCurrency = () => {
  selectedCurrencyCodes.value.push(currencyCodeToAdd.value);
  currencyCodeToAdd.value = 'USD';
};
const disableAddButton = computed(() => selectedCurrencyCodes.value.length >= 8 || editting.value);

// active row
const activeRow = ref(0);
const activeRowCurrency = computed(() => selectedCurrencies.value[activeRow.value]);
const activeValue = ref('');
const getRate = (from, to) => {
  if (from === to)
    return 1;
  else
    return props.rates[to] / props.rates[from.code];
};
const setActive = (index) => {
  activeRow.value = index;
};
const handleInput = (index, value) => {
  setActive(index);
  activeValue.value = value;
};

// editting mode
const editting = ref(false);
const toggleEdit = () => {
  editting.value = !editting.value;
};
const removeSelectedCurrency = (index) => {
  if (activeRow.value === index) {
    activeRow.value = 0;
  }
  selectedCurrencyCodes.value.splice(index, 1);
};
</script>

<style lang="scss" scoped>
  #actions {
    display: flex;
    gap: 5px;
    padding: 10px 3px;
    border-top: 1px solid var(--bulma-primary);
  }
</style>