<template>
  <div
    class="convert-row"
    :class="{ active }"
  >
    <div class="convert-row__left">
      <button
        v-if="editting"
        class="delete"
        @click.stop="$emit('remove-currency')"
      />
      <BaseCurrencySelect
        :value="currencyCode"
        :disabled="editting"
        @currency-changed="$emit('currency-changed', $event)"
      />
    </div>

    <div class="convert-row__right">
      <input
        v-model="inputValue"
        class="number-input input is-medium"
        type="number"
        inputmode="decimal"
        :placeholder="placeholder"
        :disabled="editting"
        @input.stop="$emit('input', inputValue)"
      >
      <div class="currency-name has-text-grey-light">
        {{ `${currency.name} ${currency.symbol}` }}
      </div>
    </div>
  </div>
</template>

<script setup>
  import { defineProps, defineEmits, ref, watch, computed } from 'vue';
  import BaseCurrencySelect from './BaseCurrencySelect.vue';

  const emit = defineEmits(['input', 'remove-currency', 'currency-changed']);

  const props = defineProps({
    currency: {
      type: Object,
      default: () => ({ code: 'USD' })
    },
    rate: {
      type: Number,
      default: 1
    },
    activeValue: {
      type: String,
      default: ''
    },
    active: Boolean,
    editting: Boolean
  });

  const currencyCode = computed(() => props.currency.code);
  const inputValue = ref('');
  const placeholder = computed(() => {
    if (props.active)
      return '100';
    else
      return (100 * props.rate).toFixed(4);
  });

  watch(
    () => props.activeValue,
    (newValue) => {
      if (props.active)
        return;

      if (newValue === 0)
        inputValue.value = '0'
      else if (newValue)
        inputValue.value = (newValue * props.rate).toFixed(4);
      else
        inputValue.value = '';
    },
    { immediate: true }
  );
</script>

<style lang="scss" scoped>
.convert-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px;

  &.active {
    background-color: #f0f0f0;
  }
}

.convert-row__left {
  display: flex;
  align-items: center;
  gap: 5px;
}

.convert-row__right {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .currency-name {
    font-size: 0.6rem;
  }
}

input {
  padding: 0;
  text-align: right;
  border: none;
  box-shadow: none;
  background: transparent;
  height: 40px;

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
}
</style>