import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import CurrencyConvert from '../components/CurrencyConvert.vue'
const i18nYamls = import.meta.glob('../../../config/locales/*.yml')

document.addEventListener("DOMContentLoaded", async () => {
  const appLocale = document.getElementById('appLocale').dataset.locale
  const i18nParams = {
    locale: appLocale,
    fallbackLocale: 'en',
    messages: {}
  }
  i18nParams.messages  = (await i18nYamls[`../../../config/locales/en.yml`]()).default
  if (appLocale !== 'en') {
    i18nParams.messages = {
      ...i18nParams.messages,
      ...(await i18nYamls[`../../../config/locales/${appLocale}.yml`]()).default
    }
  }
  const i18n = createI18n(i18nParams)

  const appProps = document.getElementById('appProps').dataset.props
  const app = createApp(CurrencyConvert, JSON.parse(appProps))
  app.use(i18n)
  app.mount('#app')
});